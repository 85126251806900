<template>
  <component v-bind="$attrs" :is="dynamicTag" :to="to" :class="unsetAll ? $attrs.class : classNames.root">
    <slot name="emoji" />
    <UiInfo
      v-if="hasText"
      :loading="props.loading"
      :title="title"
      :image-size="iconSize"
      :image="icon"
      :image-alt="iconAlt"
      :clamp="false"
      :subtitle="subtitle"
      :classes="classNames.info"
      :has-image="!!icon"
    >
      <template v-for="(_, name) in $slots as {}" #[fixSlotName(name)]="slotData">
        <slot v-bind="slotData || {}" :name="name" />
      </template>
    </UiInfo>
    <slot />
  </component>
</template>

<script setup lang="ts">
import { VariantProps, cva } from "class-variance-authority"

import { UiInfoClasses } from "./Info.vue"
import { nuxtIconVariants } from "./base/Icon.vue"

export interface UiBannerClasses {
  root?: ClassValue
  info?: Partial<UiInfoClasses>
}

export interface UiBannerProps {
  classes?: UiBannerClasses
  to?: TypedRouteProps | string
  variant?: VariantProps<typeof bannerVariants>["variant"]
  size?: VariantProps<typeof bannerVariants>["size"]
  unsetAll?: boolean
  loading?: boolean
  icon?: string
  iconAlt?: string
  textClasses?: string
  subtitle?: string
  iconSize?: VariantProps<typeof nuxtIconVariants>["iconSize"]
  childVariant?: VariantProps<typeof bannerVariants>["variant"]
  title?: string

  // Classes Props
  subtitleClasses?: string
  titleClasses?: string
}

const props = defineProps<UiBannerProps>()
const attrs = useAttrs()

const fixSlotName = (name: string) => name.replace("info:", "")
const hasText = computed(() => props.title || props.subtitle)

const dynamicTag = computed(() => (props.to ? resolveComponent("UiLink") : attrs.onClick ? "button" : "div"))

const bannerVariants = cva("rounded-lg flex items-center md:flex-col md:items-start gap-xs", {
  variants: {
    variant: {
      default: "bg-alert",
      info: "bg-info",
      alternative: "bg-alternative text-alternative-foreground",
      warning: "bg-warn text-warn-foreground",
      pending: "bg-pending text-pending-foreground",
      success: "bg-success",
      error: "bg-error text-error-foreground p-xxs",
    },
    size: {
      default: "w-full p-xxs",
      xs: "w-full p-xxs",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
})

const classNames = computed((): UiBannerClasses => {
  return {
    root: cn(bannerVariants({ variant: props.variant, class: props.classes?.root })),
    info: {
      ...props.classes?.info,
      title: cn([props.classes?.info?.title, { "font-medium": props.title && !props.subtitle }]),
    },
  }
})
</script>

<style scoped></style>
